import * as React from "react";
import { fetchUtils, Admin, Resource, } from 'react-admin';
import { ChallengeList } from './challenges';
import jsonServerProvider from 'ra-data-json-server';
import authProvider from './authProvider';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('Authorization', 'e5123fc7-9a7a-4534-9ee6-84c296c823ed');
    return fetchUtils.fetchJson(url, options);
};
// https://rest.joincheer.app/v1/admin
// http://localhost:9904/v1/admin
const dataProvider = jsonServerProvider('https://rest.joincheer.app/v1/admin', httpClient);

const App = () => (
        <Admin dataProvider={dataProvider} authProvider={authProvider}>
            <Resource name="challenge" list={ChallengeList} />
        </Admin>
    );

export default App;