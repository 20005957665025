export default {
    login: ({ username, password }) => { 
        if(username === "admin" && password === "admin") {
            localStorage.setItem('auth', JSON.stringify({username:"Admin"}));
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
     },
     checkError: (error) => {
        return Promise.reject();
    },
    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            var auth = localStorage.getItem('auth');
            if(auth == null){
                return Promise.reject("unauthorized");    
            }
            const id=1, fullName=JSON.parse(auth).username, avatar=null;
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: params => Promise.resolve(),
};