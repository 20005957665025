import * as React from "react";
import { List, Datagrid, FunctionField, TextField, TextInput, Filter } from 'react-admin';

// const ChallengeFilter = (props) => (
//     <Filter {...props}>
//         <TextInput label="Search" source="q" alwaysOn />
//         <TextInput label="Title" source="title" defaultValue="Hello, World!" />
//     </Filter>
// );

export const ChallengeList = props => (
    <List {...props} 
    // filters={<ChallengeFilter />}
    >
        <Datagrid 
        // rowClick="edit"
        >
            <TextField source="challenge.id" sortable={false} label="Id"/>
            <TextField source="challenge.title" sortable={false} label="Title"/>
            <FunctionField
                label="Created"
                render={record => `${formatDate(record.challenge.created)}`}
            />
            <FunctionField
                label="Start"
                render={record => `${formatDate(record.challenge.start)}`}
            />
            <FunctionField
                label="Finish"
                render={record => `${formatDate(record.challenge.finish)}`}
            />
            <TextField source="createdUser.user.id" sortable={false} label="User Id"/>
            <FunctionField
                label="User Name"
                render={record => `${record.createdUser.user.name.startsWith("*")?"---":record.createdUser.user.name}`}
            />
            {/* <EmailField source="email" />
            <TextField source="phone" />
            <UrlField source="website" />
            <TextField source="company.name" />  */}
        </Datagrid>
    </List>
);

function formatDate(i){
    var d = new Date(0);
    d.setUTCMilliseconds(i);
    return d.toLocaleString();
}